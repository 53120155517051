// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-r-js": () => import("./../../../src/pages/r.js" /* webpackChunkName: "component---src-pages-r-js" */),
  "component---src-pages-reference-success-js": () => import("./../../../src/pages/reference-success.js" /* webpackChunkName: "component---src-pages-reference-success-js" */),
  "component---src-pages-t-js": () => import("./../../../src/pages/t.js" /* webpackChunkName: "component---src-pages-t-js" */),
  "component---src-pages-wallet-success-js": () => import("./../../../src/pages/wallet-success.js" /* webpackChunkName: "component---src-pages-wallet-success-js" */)
}

